<template>
	<b-row>
		<b-col md="12 my-30">
			<b-card>
				<b-row>
					<b-col>
						<a v-b-tooltip.hover href="/loan/application">
							<i class="i-Arrow-Left-2 text-10" />
							{{ $t("Back to List", "Back to List") }}
						</a>
					</b-col>
				</b-row>
				<form-wizard ref="wizard" title subtitle color="#54A7DC" step-size="xs" :hide-buttons="true">
					<template #step="props">
						<wizard-step :key="props.tab.title" :tab="props.tab" :transition="props.transition" :index="props.index" />
					</template>
					<tab-content :title="$t('Loan Providers', 'Loan Providers')" icon="i-Business-Man">
						<penyedia @on-next="onNext" />
					</tab-content>
					<tab-content :title="$t('Loan Product', 'Loan Product')" icon="i-Shop">
						<produk :data-package="dataPackage" />
					</tab-content>
					<tab-content :title="$t('Farmer', 'Farmer')" icon="i-Farmer">
						<petani />
					</tab-content>
					<tab-content :title="$t('Collection of Goods', 'Collection of Goods')" icon="i-Truck">
						<delivery :data-package="dataPackage" />
					</tab-content>
					<tab-content :title="$t('Upload Documents', 'Upload Documents')" icon="i-File">
						<document :data-package="dataPackage" />
					</tab-content>
				</form-wizard>
			</b-card>
		</b-col>
	</b-row>
</template>
<script>
	import { mapActions, mapGetters } from "vuex"
	import Penyedia from "./_penyedia"
	import Produk from "./_produk"
	import Petani from "./_petani"
	import Delivery from "./_delivery"
	import Document from "./_document"

	export default {
		name: "UtamaPage",
		components: {
			Penyedia,
			Produk,
			Petani,
			Delivery,
			Document,
		},
		data() {
			return {
				isIconStatus: "i-Yes",
				dataPackage: [
					{
						loanPackageType: "",
					},
				],
			}
		},
		computed: {
			...mapGetters({
				getLoanFXRecords: "LOANFX/getLoanFXRecords",
			}),
		},
		created() {
			// set componenent name
			this.$root.$refs.UtamaPage_component = this
		},
		mounted() {
			this.actGetBankLoan()
			this.ActPlatform()
			this.getDocumentTypeList()
			this.getGenderList()
			this.getDocumentTypeListothers()
		},
		methods: {
			...mapActions({
				actGetBankLoan: "MASTER/actGetBankLoan",
				ActPlatform: "LOANFX/ActPlatform",
				getDocumentTypeList: "LOANFX/ActDocumentListRegister",
				getGenderList: "OTHERS/getGenderList",
				getDocumentTypeListothers: "OTHERS/getDocumentTypeList", // ini berbeda dengan doc yg di register
			}),
			onNext(id) {
				let data = this.getLoanFXRecords.filter((resp) => {
					return resp.loanPackageId === id
				})
				this.dataPackage = data
				this.$refs.wizard.nextTab()
			},
			nextPage() {
				this.$refs.wizard.nextTab()
			},
			previousPage() {
				this.$refs.wizard.prevTab()
			},
		},
	}
</script>

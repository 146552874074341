<template>
	<b-row>
		<b-col cols="12" md="12">
			<b-row>
				<b-form-group class="col-md-6">
					<ktv-input v-model="form.BusinessType" type="text" :label="$t('Farm Type', 'Farm Type')" />
				</b-form-group>
				<b-form-group class="col-md-6">
					<ktv-input v-model="form.BusinessEst" type="text" :label="$t('Last Transaction', 'Last Transaction')" />
				</b-form-group>
			</b-row>
		</b-col>
		<b-col cols="12" md="12">
			<b-card
				border-variant="primary"
				:header="$t('Selling Transaction Last 6 Months', 'Selling Transaction Last 6 Months')"
				header-bg-variant="primary"
				header-text-variant="white"
				align="left"
				no-body
				class="mb-2"
			/>
			<b-row>
				<b-form-group class="col-md-6 mb-2">
					<ktv-input v-model="form.Amount" type="text" :label="$t('Amount', 'Amount')" />
				</b-form-group>
				<b-form-group class="col-md-6 mb-2">
					<ktv-input v-model="form.NumberOfTransaction" type="text" :label="capitalize($t('Number of transaction', 'Number of transaction'))" />
				</b-form-group>
			</b-row>
		</b-col>
		<b-col cols="12" md="12">
			<b-card
				border-variant="primary"
				:header="$t('Farm List', 'Farm List')"
				header-bg-variant="primary"
				header-text-variant="white"
				align="left"
				no-body
				class="mb-2"
			/>
			<ktv-button :text="$t('Add New Farm List', 'Add New Farm List')" @click="addItem" />
			<hr style="margin: 12px" />
			<b-row v-for="(item, k) in items" :key="k">
				<b-form-group class="col-md-3">
					<ktv-input v-model="item.businessName" type="text" :label="$t('Farm Name', 'Farm Name')" />
				</b-form-group>
				<b-form-group class="col-md-2">
					<ktv-input v-model="item.businessSize" type="text" :label="`${$t('Size', 'Size')} (Ha)`" />
				</b-form-group>
				<b-form-group class="col-md-2">
					<ktv-input v-model="item.businessProduction" type="number" :label="$t('Production', 'Production') + ' (Kg)'" />
				</b-form-group>
				<b-form-group class="col-md-2">
					<ktv-input v-model="item.latitude" type="text" :label="$t('Latitude', 'Latitude')" />
				</b-form-group>
				<b-form-group class="col-md-2">
					<ktv-input v-model="item.longitude" type="text" :label="$t('Longitude', 'Longitude')" />
				</b-form-group>
				<b-form-group class="col-md-1 mt-4">
					<i v-show="k || (!k && items.length > 1)" class="i-Close-Window fa-2x" style="cursor: pointer" @click="remove(k)" />
				</b-form-group>
			</b-row>
		</b-col>
	</b-row>
</template>
<script>
	import { KtvButton, KtvInput } from "@/components"

	export default {
		name: "BussinessPage",
		components: { KtvButton, KtvInput },
		data() {
			return {
				form: {
					BusinessType: "farmer",
					BusinessEst: "",
					Amount: "",
					NumberOfTransaction: "",
				},
				items: [
					{
						businessName: "",
						businessSize: "",
						businessProduction: "",
						latitude: "",
						longitude: "",
					},
				],
			}
		},
		created() {},
		methods: {
			addItem() {
				this.items.push({
					businessName: "",
					businessSize: "",
					businessProduction: "",
					latitude: "",
					longitude: "",
				})
			},
			capitalize(text) {
				if (!text) {
					return ""
				}

				let arr = []
				let exludeWords = ["of", "the", "by", "with"]
				arr = text.split(" ")

				return arr
					.map((word, i) => {
						return exludeWords.includes(word) && i !== 0 ? [word] : word.charAt(0).toUpperCase() + word.slice(1)
					})
					.join(" ")
			},
			remove(index) {
				this.items.splice(index, 1)
			},
		},
	}
</script>
<style>
	.input {
		background: #fff;
	}
</style>

<template>
	<div>
		<!-- Loan Data -->
		<b-row>
			<b-form-group class="col-md-3 mb-2">
				<ktv-select
					v-model="form.countryId"
					rules="required"
					:options="options.countryOptions"
					:label="$t('Country', 'Country')"
					@input="setProvinceByCountry"
				/>
			</b-form-group>
			<b-form-group class="col-md-3 mb-2">
				<ktv-select
					v-model="form.Province"
					rules="required"
					:loading="loadingProvince"
					:options="options.provinceOptions"
					:label="$t('Province', 'Province')"
					@input="setDistrict"
				/>
			</b-form-group>
			<b-form-group class="col-md-3 mb-2">
				<ktv-select
					v-model="form.District"
					rules="required"
					:loading="loadingDisctrict"
					:options="options.districtOptions"
					:label="$t('District', 'District')"
					@input="setSubDistrict"
				/>
			</b-form-group>
			<b-form-group class="col-md-3 mb-2">
				<ktv-select
					v-model="form.SubDistrict"
					rules="required"
					:loading="loadingSubDisctrict"
					:options="options.subDistrictOptions"
					:label="$t('Sub District', 'Sub District')"
					@input="setVillages"
				/>
			</b-form-group>

			<b-form-group class="col-md-3 mb-2">
				<ktv-select v-model="form.Village" :loading="loadingVillage" :options="options.villageOptions" :label="$t('Village', 'Village')" />
			</b-form-group>
			<b-form-group class="col-md-9 mb-2">
				<ktv-input v-model="form.Address" rules="required" :label="$t('Address', 'Address')" />
			</b-form-group>
		</b-row>
	</div>
</template>
<script>
	import { mapState, mapActions } from "vuex"
	import { map } from "underscore"
	import { KtvInput, KtvSelect } from "@/components"

	export default {
		components: { KtvInput, KtvSelect },
		data() {
			return {
				requredLabel: '<span class="text-danger">*</span>',
				options: {
					countryOptions: [],
					provinceOptions: [],
					districtOptions: [],
					subDistrictOptions: [],
					villageOptions: [],
				},
				form: {
					countryId: "",
					Province: "",
					District: "",
					SubDistrict: "",
					Village: "",
					Address: "",
				},
				loadingProvince : false,
				loadingDisctrict: false,
				loadingSubDisctrict: false,
				loadingVillage: false,
			}
		},
		computed: {
			...mapState("OTHERS", ["countryList", "provinceList", "districtList", "subDistrictList", "villageList"]),
		},
		watch: {
			countryList: {
				deep: true,
				handler() {
					this.getCountryOptions()
				},
				immediate: true,
			},
			provinceList: {
				deep: true,
				handler() {
					this.getProvinceOptions()
				},
				immediate: true,
			},
			districtList: {
				deep: true,
				handler() {
					this.getDistrictOptions()
				},
				immediate: true,
			},
			subDistrictList: {
				deep: true,
				handler() {
					this.getSubDistrictOptions()
				},
				immediate: true,
			},
			villageList: {
				deep: true,
				handler() {
					this.getVillagesOptions()
				},
				immediate: true,
			},
		},
		methods: {
			...mapActions({
				getCountryList: "OTHERS/getCountryList",
				getProvinceList: "OTHERS/getProvinceList",
				getDistrictList: "OTHERS/getDistrictList",
				getSubDistrictList: "OTHERS/getSubDistrictList",
				getVillageList: "OTHERS/getVillageList",
			}),
			getCountryOptions() {
				this.options.countryOptions = map(this.countryList, (c) => {
					return {
						text: c.country,
						value: c.countryCode,
					}
				})
			},
			getProvinceOptions() {
				this.options.provinceOptions = map(this.provinceList, (province) => {
					return {
						text: province.province,
						value: parseInt(province.entityProvinceID, 10),
					}
				})
			},
			getDistrictOptions() { 
				this.options.districtOptions = map(this.districtList, (district) => {
					this.loadingDisctrict = false
					return {
						text: district.district,
						value: parseInt(district.entityDistrictID, 10),
					}
				})
			},
			getSubDistrictOptions() { 
				this.options.subDistrictOptions = map(this.subDistrictList, (subdistrict) => {
					this.loadingSubDisctrict = false
					return {
						text: subdistrict.subDistrict,
						value: parseInt(subdistrict.entitySubDistrictID, 10),
					}
				})
			},
			getVillagesOptions() { 
				this.options.villageOptions = map(this.villageList, (v) => {
					this.loadingVillage = false
					return {
						text: v.village,
						value: parseInt(v.entityVillageID, 10),
					}
				})
			},
			setProvinceByCountry(value){
				this.loadingpr = true
				if (value !== null) {
					this.getProvinceList(value)
				} else { 
					this.options.provinceOptions = []
				}
			},
			setDistrict(value) {
				this.loadingDisctrict = true
				if (value !== null) {
					this.getDistrictList(value)
				} else {
					this.loadingDisctrict = false
					this.options.districtOptions = []
				}
			},
			setSubDistrict(value) {
				this.loadingSubDisctrict = true
				if (value !== null) {
					this.getSubDistrictList(value)
				} else {
					this.loadingSubDisctrict = false
					this.options.subDistrictOptions = []
				}
			},
			setVillages(value) {
				this.loadingVillage = true
				if (value !== null) {
					this.getVillageList(value)
				} else {
					this.loadingVillage = false
					this.options.villageOptions = []
				}
			},
		},
	}
</script>

<template>
	<b-row>
		<b-col md="12">
			<ktv-card-with-header :header="$t('Farmer', 'Farmer')">
				<template #content>
					<b-row>
						<b-form-group class="col-md-4 mb-2 font-weight-bold" :label="$t('Farmer Status', 'Farmer Status')">
							<b-form-radio-group
								v-model="PilihanRegister"
								class="mb-3 font-weight-normal"
								value-field="value"
								text-field="label"
								@input="changeOption"
							>
								<b-form-radio :value="true">{{ $t("Registered Farmer", "Registered Farmer") }}</b-form-radio>
								<b-form-radio :value="false">{{ $t("Unregistered Farmer", "Unregistered Farmer") }}</b-form-radio>
							</b-form-radio-group>
						</b-form-group>
						<b-form-group v-show="!isFarmer" class="col-md-3 mb-2 font-weight-bold" :label="$t('Farmer ID', 'Farmer ID')">
							<b-form-input v-model="TextFarmerID" class="input" type="text" />
						</b-form-group>
						<b-form-group v-show="!isFarmer" class="col-md-3" :label="$t('Commodity', 'Commodity')">
							<v-select v-model="TextFarmerPlatform" :options="optionPlatform" class="input" />
						</b-form-group>
						<b-form-group class="col-md-2 mt-4">
							<ktv-button v-show="!isFarmer" :text="$t('Search', 'Search')" @click="onSearch" />
						</b-form-group>
					</b-row>
					<vcl-facebook v-show="loading" />
					<div v-show="!loading" v-if="isSearch">
						<validation-observer ref="form_add_petani" tag="form">
							<b-card
								border-variant="primary"
								:header="$t('Personal Data', 'Personal Data')"
								header-bg-variant="primary"
								header-text-variant="white"
								align="left"
								no-body
								class="mb-2"
							/>
							<form-personal ref="validatePersonal" :isfarmer="isFarmer" />
						</validation-observer>

						<!-- Family Section -->
						<b-card
							v-show="isFarmer"
							:header="$t('Family', 'Family')"
							align="left"
							border-variant="primary"
							class="mb-2"
							header-bg-variant="primary"
							header-text-variant="white"
							no-body
						/>
						<form-family v-show="isFarmer" ref="validateFamily" />

						<!-- Certification Section -->
						<b-card
							v-show="isFarmer"
							:header="$t('Certification', 'Certification')"
							border-variant="primary"
							header-bg-variant="primary"
							header-text-variant="white"
							align="left"
							no-body
							class="mb-2"
						/>
						<form-certified v-show="isFarmer" ref="validateCertified" />

						<!-- Address Section -->
						<validation-observer ref="form_add_petani2" tag="form">
							<b-card
								v-show="isFarmer"
								:header="$t('Address', 'Address')"
								align="left"
								border-variant="primary"
								class="mb-2"
								header-bg-variant="primary"
								header-text-variant="white"
								no-body
							/>
							<form-address v-show="isFarmer" ref="validateAddress" />
						</validation-observer>

						<!-- Contact Section -->
						<b-card
							border-variant="primary"
							:header="$t('Contact', 'Contact')"
							header-bg-variant="primary"
							header-text-variant="white"
							align="left"
							no-body
							class="mb-2"
						/>
						<form-contact ref="validateContact" :isfarmer="isFarmer" />

						<!-- Bank Account Section -->
						<b-card
							border-variant="primary"
							:header="$t('Bank Account', 'Bank Account')"
							header-bg-variant="primary"
							header-text-variant="white"
							align="left"
							no-body
							class="mb-2"
						/>
						<form-bank ref="validateBank" :isfarmer="isFarmer" />

						<b-card
							v-show="isFarmer"
							border-variant="primary"
							:header="$t('Farmer Data', 'Farmer Data')"
							header-bg-variant="primary"
							header-text-variant="white"
							align="left"
							no-body
							class="mb-2"
						/>
						<business v-show="isFarmer" ref="validateBusinnes" />
					</div>
				</template>
			</ktv-card-with-header>
			<b-row class="mt-3">
				<b-col align="left">
					<ktv-button :text="$t('Previous Page', 'Previous Page')" @click="$root.$refs.UtamaPage_component.previousPage()" />
				</b-col>
				<b-col align="right">
					<ktv-button :text="$t('Next Page', 'Next Page')" @click="onSubmit" />
				</b-col>
			</b-row>
		</b-col>
		<ktv-modal v-model="modalsFilter" size="lg" :title="$t('Suggestion', 'Suggestion')" @close="modalsFilter = false">
			<template #content>
				<b-table
					v-show="!loading"
					id="table-id"
					ref="my-table"
					:key="items.id"
					:items="items"
					sticky-header
					responsive
					no-border-collapse
					show-empty
					primary-key="entityId"
					style="height: 400px"
				>
					<template #head(id)>
						<div style="width: 50px">{{ $t("ID", "ID") }}</div>
					</template>
					<template #head(FarmerName)="data">
						<div style="width: 150px">{{ $t(data.label, data.label) }}</div>
					</template>
					<template #head(FarmerId)>
						<div style="width: 150px">{{ $t("Farmer ID", "Farmer ID") }}</div>
					</template>
					<template #head(IDCard)>
						<div style="width: 150px">{{ $t("ID Card", "ID Card") }}</div>
					</template>

					<template #head(Country)="data">
						<div style="width: 100px">{{ $t(data.label, data.label) }}</div>
					</template>
					<template #head(Province)="data">
						<div style="width: 100px">{{ $t(data.label, data.label) }}</div>
					</template>
					<template #head(District)="data">
						<div style="width: 150px">{{ $t(data.label, data.label) }}</div>
					</template>
					<template #head(SubDistrict)="data">
						<div style="width: 150px">{{ $t(data.label, data.label) }}</div>
					</template>
					<template #head(Action)="data">
						<div style="width: 150px">{{ $t(data.label, data.label) }}</div>
					</template>
					<template #cell(Action)="data">
						<ktv-button :text="$t('Select', 'Select')" color="primary" @click="onSelectSuggest(data)" />
					</template>
				</b-table>
			</template>
		</ktv-modal>
	</b-row>
</template>
<script>
	import { mapActions, mapState, mapGetters } from "vuex"
	import { KtvCardWithHeader, KtvButton, KtvModal } from "@/components"
	import FormPersonal from "./_petani-form/FormPersonal"
	import FormFamily from "./_petani-form/FormFamily"
	import FormCertified from "./_petani-form/FormCertified"
	import FormAddress from "./_petani-form/FormAddress"
	import FormContact from "./_petani-form/FormContact"
	import FormBank from "./_petani-form/FormBank"
	import Business from "./_business"
	import { map } from "underscore"
	import { ValidationObserver } from "vee-validate"

	export default {
		name: "PetaniPage",
		components: {
			KtvButton,
			KtvCardWithHeader,
			KtvModal,
			FormPersonal,
			FormFamily,
			FormCertified,
			FormAddress,
			FormContact,
			FormBank,
			Business,
			ValidationObserver,
		},
		data() {
			return {
				modalsFilter: false,
				PilihanRegister: true,
				pilihanreg: [
					{ value: true, label: this.$t("Registered Farmer", "Registered Farmer") },
					{ value: false, label: this.$t("Unregistered Farmer", "Unregistered Farmer") },
				],
				TextFarmerID: "",
				TextFarmerPlatform: "",
				optionPlatform: [],
				isFarmer: false,
				loading: false,
				isSearch: false,

				/* KtvTable property */
				fields: [],
				items: [],
				/* End KtvTable property */
			}
		},
		computed: {
			...mapState("LOANFX", ["PlatformList"]),
			...mapGetters({
				getdistrictName: "LOANFX/getdistrictName",
			}),
		},
		watch: {
			PlatformList: {
				deep: true,
				handler() {
					this.getListPlatformOptions()
				},
				immediate: true,
			},
		},
		methods: {
			...mapActions({
				ActSearch: "LOANFX/ActSearch",
			}),
			getListPlatformOptions() {
				this.optionPlatform = map(this.PlatformList, (e) => {
					return {
						label: e.value,
						id: parseInt(e.entityListOfValueId, 10),
					}
				})
			},
			onSearch() {
				this.loading = true
				const payload = {
					commodityId: this.TextFarmerPlatform !== "" ? this.TextFarmerPlatform.id : "",
					entityObjectDisplayId: this.TextFarmerID,
				}

				this.ActSearch(payload)
					.then((response) => {
						this.loading = false
						if (response.length === 0) {
							this.$swal(`${this.$t("Failed", "Failed")}!`, this.$t("Farmer data not found", "Farmer data not found"), "error")
							this.isSearch = false
							this.isFarmer = false
						} else {
							this.modalsFilter = true
							let data = []
							response.forEach((value, key) => {
								data.push({
									id: key + 1,
									FarmerName: value.dataName,
									FarmerId: this.TextFarmerID,
									IDCard: value.dataIdNumber,
									Country: value.addressCountry,
									Province: value.addressProvince,
									District: value.addressDistrict,
									SubDistrict: value.addressSubDistrict,
									Action: value,
								})
							})
							this.items = data
							this.fetchItems()
						}
					})
					.catch((err) => {
						this.isSearch = false
						return err
					})
			},
			onSelectSuggest(value) {
				if (this.getdistrictName.label === value.item.Action.addressDistrict) {
					this.$store.commit("LOANFX/setSearchResult", value.item.Action)
					this.modalsFilter = false
					this.isSearch = true
				} else {
					this.$swal(
						`${this.$t("Failed", "Failed")}!`,
						this.$t(
							"selected farmer district is not in same district of loan package",
							"selected farmer district is not in same district of loan package",
						),
						"error",
					)
				}
			},
			async fetchItems() {
				/* No need to call if all items retrieved */
				if (this.items.length === this.totalItems) return

				/* Enable busy state */
				this.isBusy = true

				/* Missing error handling if call fails */
				const startIndex = (this.currentPage + 1) * this.perPage
				const endIndex = startIndex + this.perPage
				const newItems = await this.callDatabase(startIndex, endIndex)

				/* Add new items to existing ones */
				this.items = this.items.concat(newItems)
				/* Disable busy state */
				this.isBusy = false
			},
			/* mock database method */
			callDatabase(startIndex, endIndex) {
				return new Promise((resolve) => {
					const delay = Math.floor(Math.random() * 1250) + 250
					setTimeout(() => {
						resolve(this.items.slice(startIndex, endIndex))
					}, delay)
				})
			},
			onScroll(event) {
				if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
					if (!this.isBusy) {
						this.fetchItems()
					}
				}
			},
			changeOption(event) {
				if (event === false) {
					this.isSearch = true
					this.loading = false
					this.isFarmer = true
				} else {
					this.isSearch = false
					this.isFarmer = false
				}
			},
			onSubmit: async function () {
				// Validate before submit form
				let FormPetani = {
					...this.$refs.validatePersonal.form,
					...this.$refs.validateFamily.form,
					...this.$refs.validateAddress.form,
					...this.$refs.validateCertified.form,
					...this.$refs.validateContact.form,
					...this.$refs.validateBank.form,
				}
				// Businness Form
				let FormBusinness = {
					...this.$refs.validateBusinnes.form,
					list: this.$refs.validateBusinnes.items,
				}

				if (this.isFarmer) {
					let validTani = await this.$refs.form_add_petani.validate()
					let validTani2 = await this.$refs.form_add_petani2.validate() // addrress
					if (validTani && validTani2) {
						this.$store.commit("LOANFX/setLoanPetaniFX", FormPetani)
						this.$store.commit("LOANFX/setLoanBusinnessFX", FormBusinness)
						this.$root.$refs.UtamaPage_component.nextPage()
						return
					}

					this.$swal(
						`${this.$t("Failed", "Failed")}!`,
						`${this.$t("Please, enter the required fields", "Please, enter the required fields")}`,
						"error",
					)
				} else {
					let validTani = await this.$refs.form_add_petani.validate()
					if (validTani) {
						this.$store.commit("LOANFX/setLoanPetaniFX", FormPetani)
						this.$store.commit("LOANFX/setLoanBusinnessFX", FormBusinness)
						this.$root.$refs.UtamaPage_component.nextPage()
						return
					}

					this.$swal(
						`${this.$t("Failed", "Failed")}!`,
						`${this.$t("Please, enter the required fields", "Please, enter the required fields")}`,
						"error",
					)
				}
			},
		},
	}
</script>
<style scoped>
	.vgt-global-search.vgt-clearfix {
		height: 0px;
	}
	.vgt-inner-wrap {
		border: none;
	}
</style>

<template>
	<div>
		<!-- Loan Data -->
		<b-row>
			<b-form-group class="col-md-6 mb-2" :label="$t('Status', 'Status')">
				<b-form-radio-group v-model="form.StatusCert" :options="options" class="mb-3" value-field="value" text-field="label" />
			</b-form-group>
		</b-row>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				loading: true,
				form: {
					StatusCert: "",
					TypeCert: "",
				},
			}
		},
		computed: {
			options() {
				return [
					{ label: this.$t("Yes", "Yes"), value: true },
					{ label: this.$t("No", "No"), value: false },
				]
			},
		},
	}
</script>

<style>
	.input {
		background: #fff;
	}
</style>

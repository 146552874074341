<template>
	<div>
		<!-- Loan Data --> 
		<b-row>
			<b-form-group class="col-md-6 mb-2"> 
				<ktv-select
					v-model="form.BankName"  
					:options="options.listofbank"
					:label="$t('Bank Name', 'Bank Name')" 
				/> 
			</b-form-group>
			<b-form-group class="col-md-6 mb-2"> 
				<ktv-input v-model="form.BankBranch" :label="$t('Bank Branch', 'Bank Branch')" />
			</b-form-group>
			<b-form-group class="col-md-6 mb-2"> 
				<ktv-input v-model="form.AccountHolderName" :label="$t('Name of Account Holder', 'Name of Account Holder')" />
			</b-form-group>
			<b-form-group class="col-md-6 mb-2"> 
				<ktv-input v-model="form.AccountNumber" type="number" :label="$t('Account Number', 'Account Number')" />
			</b-form-group> 
		</b-row>  
	</div>
</template>
<script>
	import { mapState, mapActions, mapGetters } from "vuex"
	import { KtvInput,KtvSelect } from "@/components" 
	import { map } from "underscore"
	
	export default {
		components:{KtvInput,KtvSelect},
		props:{
			isfarmer: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				loading: true,
				form: {
					BankName: "",
					BankBranch: "",
					AccountHolderName: "",
					AccountNumber: "",
				},
				options: {
					listofbank : []
				}
			}
		}, 
		computed: {
			...mapState("MASTER", ["optionlistOfBank"]),
			...mapGetters({
				getOptionlistOfBank : 'MASTER/getOptionlistOfBank',
				getSearchResult : 'LOANFX/getSearchResult'
			})
		}, 
		watch: {
			optionlistOfBank:{
				deep: true,
				handler(){
					this.getListBankOptions()
				},
				immediate: true,
			},
			dataFormSearch: {
				deep: true,
				handler() {
					this.getDatafromSearch()
				},
				immediate: true,
			},
		},
		methods: {
			...mapActions({}),
			getListBankOptions() { 
				this.options.listofbank = map(this.optionlistOfBank, (e) => {
					return {
						text: e.bankName,
						value: parseInt(e.bankId, 10),
					}
				})
			},
			getDatafromSearch() { 
				if (this.getSearchResult.length > 0) { 
					this.form.BankBranch = this.getSearchResult[0].BankBranch 
					this.form.AccountHolderName = this.getSearchResult[0].AccountHolderName
					this.form.AccountNumber = this.getSearchResult[0].AccountNumber   
 
					this.getOptionlistOfBank.forEach(v => { 
						if (v.entityListOfValueId === parseInt(this.getSearchResult[0].accountBankId, 10) ) {
							this.form.BankName = {
								text: v.bankName,
								value: v.bankId,
							}
						}
					}); 
					
				}
			},
			getisfarmer(){
				if (this.isfarmer !== false) {  
					this.form.BankName = "" 
					this.form.BankBranch = "" 
					this.form.AccountHolderName = "" 
					this.form.AccountNumber = "" 
				} 
			}
		},
	}
</script>
<style>
	.input {
		background: #fff;
	}
</style>

<template>
	<div>
		<b-row>
			<b-form-group class="col-md-4 mb-2">
				<ktv-input v-model="form.Name" rules="required" :label="$t('Name', 'Name')" />
				<b-form-input v-model="form.entityUid" style="display: none" />
			</b-form-group>
			<b-form-group class="col-md-3 mb-2">
				<ktv-select v-model="form.Gender" rules="required" :options="options.gender" :label="$t('Gender', 'Gender')" />
			</b-form-group>
			<b-form-group class="col-md-3 mb-2">
				<ktv-select v-model="form.IDType" rules="required" :options="options.docType" :label="$t('ID Type', 'ID Type')" />
			</b-form-group>
			<b-form-group class="col-md-4 mb-2">
				<ktv-input v-model="form.IDNumber" type="number" rules="required" :label="$t('ID Number', 'ID Number')" />
			</b-form-group>
			<b-form-group label-for="input-1" class="col-md-3">
				<ktv-input v-model="form.BirthDate" type="date" rules="required" :label="$t('Birth Date', 'Birth Date')" :max="YearMax" @input="getAge" />
			</b-form-group>
			<b-form-group class="col-md-2 mb-2">
				<ktv-input v-model="form.Age" rules="required" :label="$t('Age', 'Age')" :readonly="true" />
			</b-form-group>
		</b-row>
	</div>
</template>
<script>
	import { mapState, mapActions, mapGetters } from "vuex"
	import { map } from "underscore"
	import { KtvInput, KtvSelect } from "@/components" 
	export default {
		components: { KtvInput, KtvSelect }, 
		props: { 
			isfarmer: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				loading: true,
				errors: true,
				YearMax: "",
				options: {
					gender: [],
					docType: [],
				},
				form: {
					Name: "",
					BirthDate: "",
					IDType: "",
					Gender: "",
					Age: "",
					IDNumber: "",
					entityUid: "",
				},
			}
		},
		computed: {
			...mapState("OTHERS", ["genderList"]),
			...mapGetters({
				getSearchResult: "LOANFX/getSearchResult",
				getDocumentList: "LOANFX/getDocumentList",
				getGenderList: "OTHERS/getGenderList",
			}),
		},
		watch: {
			genderList: {
				deep: true,
				handler() {
					this.getGenderOptions()
				},
				immediate: true,
			},
			dataFormSearch: {
				deep: true,
				handler() {
					this.getDatafromSearch()
				},
				immediate: true,
			},
			isfarmer: {
				deep: true,
				handler() {
					this.getisfarmer()
				},
				immediate: true,
			},
		},
		mounted() {
			this.MaxBirtdate()
			this.getDocumentTypeOptions()
		},
		methods: {
			...mapActions({}),
			getDocumentTypeOptions() {
				this.options.docType = map(this.getDocumentList, (documentType) => {
					return {
						text: documentType.value,
						value: parseInt( documentType.entityListOfValueId , 10 )
					}
				})
			},
			getGenderOptions() { 
				this.options.gender = map(this.genderList, (el) => {
					return {
						text: el.value,
						value: el.entityListOfValueId 
					}
				}) 
			},
			getAge() {
				let date = this.form.BirthDate

				if (date !== "") {
					let today = new Date()
					let birthday = new Date(date)
					let year = 0
					if (today.getMonth() < birthday.getMonth()) {
						year = 1
					} else if (today.getMonth() === birthday.getMonth() && today.getDate() < birthday.getDate()) {
						year = 1
					}
					let age = today.getFullYear() - birthday.getFullYear() - year

					if (age < 0) {
						age = 0
					}
					this.form.Age = age
				}
			},
			getDatafromSearch() {
				if (Object.keys(this.getSearchResult).length > 0) { 
					this.form.Name = this.getSearchResult.dataName
					this.form.BirthDate = this.getSearchResult.dataBirthday
					this.form.IDNumber = this.getSearchResult.dataIdNumber
					this.form.entityUid = this.getSearchResult.entityUid
					this.getAge()
					this.form.IDType = parseInt(this.getSearchResult.dataIdType, 10)
					this.form.Gender = parseInt(this.getSearchResult.dataGender, 10)  
				}
			},
			getisfarmer() { 
				if (this.isfarmer === false) {
					this.form.entityUid = Object.keys(this.getSearchResult).length > 0 ? this.getSearchResult.entityUid : ""
				} else {
					this.form.Name = ""
					this.form.BirthDate = ""
					this.form.IDType = ""
					this.form.Gender = ""
					this.form.Age = ""
					this.form.IDNumber = ""
					this.form.entityUid = ""
				}
			},
			MaxBirtdate() {
				let dtToday = new Date()
				let month = dtToday.getMonth() + 1 // jan=0; feb=1 .......
				let day = dtToday.getDate()
				let year = dtToday.getFullYear() - 17 // syaratnya 17 thn
				if (month < 10) month = "0" + month.toString()
				if (day < 10) day = "0" + day.toString()
				let maxDate = year + "-" + month + "-" + day
				this.YearMax = maxDate
			},
		},
	}
</script>

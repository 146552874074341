<template>
	<b-row>
		<b-col md="12">
			<ktv-card-with-header :header="$t('Loan Providers', 'Loan Providers')">
				<template #content>
					<b-row>
						<b-col cols="3">
							<b-form-group>
								<template #label> {{ $t("Filter Country", "Filter Country") }} </template>
								<v-select v-model="filter.countryId" :options="options.countryOptions" @input="setProvinceByCountry" />
							</b-form-group>
						</b-col>
						<b-col cols="3">
							<b-form-group>
								<template #label>
									{{ $t("Filter Province", "Filter Province") }}
									<span v-show="loadingpr" class="text-success">{{ $t("Loading", "Loading") }}...</span>
								</template>
								<v-select v-model="filter.provinceId" :options="options.provinceOptions" @input="setDistrict" />
							</b-form-group>
						</b-col>
						<b-col cols="3">
							<b-form-group>
								<template #label>
									{{ $t("Filter District", "Filter District") }}
									<span v-show="loadingds" class="text-success">{{ $t("Loading", "Loading") }}...</span>
								</template>
								<v-select v-model="filter.districtId" :options="options.districtOptions" @input="getPenyediaPinjaman" />
							</b-form-group>
						</b-col>
					</b-row>
					<vcl-facebook v-show="loadingGrid" />
					<b-table
						v-show="!loadingGrid"
						id="table-id"
						ref="my-table"
						:key="items.id"
						:items="items"
						:fields="fields"
						sticky-header
						responsive
						no-border-collapse
						show-empty
						primary-key="id"
						style="height: 400px"
					>
						<template #head()="data">
							<span>{{ $t(data.label, data.label) }}</span>
						</template>

						<template #cell(loanInstitutionLogo)="data">
							<img :src="data.value" width="100px" />
						</template>
						<template #cell(packagetype)="data">
							<span>{{ $t(data.value, data.value) }}</span>
						</template>
						<template #cell(id)="data">
							<ktv-button :text="$t('Request Loan', 'Request Loan')" color="warning" style="color: white" @click="onGetNextPage(data.value)" />
						</template>
					</b-table>
					<b-overlay :show="isBusy" no-wrap opacity="0.5" />
				</template>
			</ktv-card-with-header>
		</b-col>
	</b-row>
</template>
<script>
	const columnsDataist = [
		{
			label: "Logo",
			key: "loanInstitutionLogo",
		},
		{
			label: "Loan Provider",
			key: "loanInstitutionName",
		},
		{
			label: "Margin",
			key: "marginPercentage",
		},
		{
			label: "Currency",
			key: "currencyName",
		},
		{
			label: "Amount Max",
			key: "totalLoanAmount",
		},
		{
			label: "Package Name",
			key: "loanPackageName",
		},
		{
			label: "Package Type",
			key: "packagetype",
		},
		{
			label: "Action",
			key: "id",
			html: true,
			tdClass: "text-center",
			thClass: "text-center",
			sortable: false,
		},
	]

	import { KtvCardWithHeader, KtvButton } from "@/components"
	import { map } from "underscore"
	import { mapGetters, mapActions, mapState } from "vuex"

	export default {
		name: "PenyediaPage",
		components: {
			KtvCardWithHeader,
			KtvButton,
		},
		emits: ["on-next"],
		data() {
			return {
				isIconStatus: "i-Yes",
				filter: {
					provinceId: null,
					districtId: null,
					countryId: null,
				},
				options: {
					provinceOptions: [],
					districtOptions: [],
					countryOptions: [],
				},
				fields: columnsDataist,
				items: [],
				currentPage: 0,
				perPage: 5,
				totalItems: 0,
				isBusy: false,
				loadingpr: false,
				loadingds: false,
				loadingGrid: false,
			}
		},
		computed: {
			...mapGetters({}),
			...mapState("OTHERS", ["provinceList", "districtList", "countryList"]),
		},
		watch: {
			/* Optionally hide scrollbar when loading */
			isBusy(newVal, oldVal) {
				if (newVal !== oldVal) {
					const tableScrollBody = this.$refs["my-table"].$el
					if (newVal === true) {
						tableScrollBody.classList.add("overflow-hidden")
					} else {
						tableScrollBody.classList.remove("overflow-hidden")
					}
				}
			},
			countryList: {
				deep: true,
				handler() {
					this.getCountryOptions()
				},
				immediate: true,
			},
			provinceList: {
				deep: true,
				handler() {
					this.getProvinceOptions()
				},
				immediate: true,
			},
			districtList: {
				deep: true,
				handler() {
					this.getDistrictOptions()
				},
				immediate: true,
			},
		},
		mounted() {
			this.getCountryList()
			// this.getPenyediaPinjaman()
		},
		methods: {
			...mapActions({
				getProvinceList: "OTHERS/getProvinceList",
				getDistrictList: "OTHERS/getDistrictList",
				getCountryList: "OTHERS/getCountryList",
				ActionTablesGrid: "LOANFX/ActionTablesGrid",
			}),
			getCountryOptions() {
				this.options.countryOptions = map(this.countryList, (c) => {
					return {
						label: c.country,
						id: c.countryCode,
					}
				})
			},
			getProvinceOptions() {
				this.options.provinceOptions = map(this.provinceList, (province) => {
					this.loadingpr = false
					return {
						label: province.province,
						id: parseInt(province.entityProvinceID, 10),
					}
				})
			},
			getDistrictOptions() {
				this.options.districtOptions = map(this.districtList, (district) => {
					this.loadingds = false
					return {
						label: district.district,
						id: parseInt(district.entityDistrictID, 10),
					}
				})
			},
			setProvinceByCountry(value) {
				this.loadingpr = true
				if (value !== null) {
					this.getProvinceList(value.id)
				} else {
					this.options.provinceOptions = []
				}
			},
			setDistrict(value) {
				this.loadingds = true
				if (value !== null) {
					this.getDistrictList(value.id)
				} else {
					this.loadingds = false
					this.options.districtOptions = []
				}
			},
			getPenyediaPinjaman() {
				this.loadingGrid = true
				if (this.filter.districtId !== null) {
					this.$store.commit("LOANFX/setdistrict", this.filter.districtId)
					this.ActionTablesGrid({ districtId: this.filter.districtId.id }).then((resp) => {
						let data = []
						resp.forEach((value) => {
							let pkg = ""
							if (value.loanPackageType === 155) {
								pkg = "Cash Only"
							}
							if (value.loanPackageType === 156) {
								pkg = "Product Only"
							}
							if (value.loanPackageType === 157) {
								pkg = "Cash and Product"
							}
							data.push({
								id: value.loanPackageId,
								loanCostumerDesc: value.loanCostumerDesc,
								currencyName: value.currencyName,
								loanInstitutionLogo: value.loanInstitutionLogo,
								loanInstitutionName: value.loanInstitutionName,
								loanPackageName: value.loanPackageName,
								marginPercentage: value.marginPercentage,
								packagetype: pkg,
								totalLoanAmount: this.formatPrice(value.totalLoanAmount),
							})
						})
						this.loadingGrid = false
						this.items = data
					})
				} else {
					this.loadingGrid = false
				}
			},
			async fetchItems() {
				/* No need to call if all items retrieved */
				if (this.items.length === this.totalItems) return

				/* Enable busy state */
				this.isBusy = true

				/* Missing error handling if call fails */
				const startIndex = (this.currentPage + 1) * this.perPage
				const endIndex = startIndex + this.perPage
				const newItems = await this.callDatabase(startIndex, endIndex)

				/* Add new items to existing ones */
				this.items = this.items.concat(newItems)
				/* Disable busy state */
				this.isBusy = false
			},
			/* mock database method */
			callDatabase(startIndex, endIndex) {
				return new Promise((resolve) => {
					const delay = Math.floor(Math.random() * 1250) + 250
					setTimeout(() => {
						resolve(this.items.slice(startIndex, endIndex))
					}, delay)
				})
			},
			onScroll(event) {
				if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
					if (!this.isBusy) {
						// this.fetchItems()
					}
				}
			},
			formatPrice(value) {
				let val = (value / 1).toFixed().replace(".", ",")
				return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
			},
			onGetNextPage(id) {
				this.$root.$refs.produkPage_component.resetFormProduk()
				this.$emit("on-next", id)
			},
		},
	}
</script>

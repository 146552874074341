<template>
	<b-row>
		<b-col md="12">
			<b-card
				border-variant="primary"
				:header="$t('Upload Documents', 'Upload Documents')"
				header-bg-variant="primary"
				header-text-variant="white"
				align="left"
				no-body
				class="mb-2"
			/>
			<b-row>
				<b-form-group
					v-for="(row, index) in documentTypeOptions"
					:key="index"
					:label="$t(capitalize(row.value), capitalize(row.value))"
					class="col-md-4 mb-2"
				>
					<b-form-file accept=".jpg,.png,.jpeg" style="background: white" @change="uploadFile($event, row.entityListOfValueId)" />
				</b-form-group>
			</b-row>
			<b-row class="mt-3">
				<b-col align="left">
					<ktv-button :text="$t('Previous Page', 'Previous Page')" @click="$root.$refs.UtamaPage_component.previousPage()" />
				</b-col>
				<b-col align="right">
					<ktv-button :text="$t('Finish and Submit', 'Finish and Submit')" color="success" @click="onSubmit" />
				</b-col>
			</b-row>
		</b-col>
	</b-row>
</template>
<script>
	import { KtvButton } from "@/components"
	import { map } from "underscore"
	import { mapActions, mapState, mapGetters } from "vuex"

	export default {
		name: "DeliveryPage",
		components: {
			KtvButton,
		},
		props: {
			dataPackage: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				documentList: [],
				documentuploadList: [],
			}
		},
		computed: {
			...mapState("OTHERS", ["documentTypeList"]),
			...mapGetters({
				loggedInUser: "AUTHDATA/loggedInUser",
				getLoanFXProduct: "LOANFX/getLoanFXProduct",
				getLoanPetaniFX: "LOANFX/getLoanPetaniFX",
				getLoanBusinnessFX: "LOANFX/getLoanBusinnessFX",
				getAmbilBarangFX: "LOANFX/getAmbilBarangFX",
			}),
			documentTypeOptions() {
				if (this.documentTypeList && this.documentTypeList.length > 0) {
					let packagex = this.dataPackage[0]
					let empIds = packagex.loanDocumentRequired

					if (empIds !== undefined) {
						const matchedDocumentList = this.documentTypeList.filter((itm) => {
							return empIds.indexOf(itm.entityListOfValueId) > -1
						})

						return map(matchedDocumentList, (documentType) => {
							return documentType && documentType.value === "Marriage Certificae"
								? { ...documentType, value: this.$t("Marriage Certificate", "Marriage Certificate") }
								: { ...documentType, value: this.$t(this.capitalize(documentType.value), this.capitalize(documentType.value)) }
						})
					}
				}

				return []
			},
		},
		methods: {
			...mapActions({
				ActionUploadS3: "CONTRACT/ActionUploadS3",
				ActionAddNewSubmit: "LOANFX/ActionAddNewSubmit",
			}),
			capitalize(text) {
				if (!text) {
					return ""
				}

				let arr = []
				let exludeWords = ["of", "the", "by", "with"]
				arr = text.split(" ")

				return arr
					.map((word, i) => {
						return exludeWords.includes(word) && i !== 0 ? [word] : word.charAt(0).toUpperCase() + word.slice(1)
					})
					.join(" ")
			},
			onSubmit() {
				let Arrayproduct = []
				if (this.getLoanFXProduct.length > 0) {
					this.getLoanFXProduct.forEach((element) => {
						let obj = {
							productId: element.productId,
							quantity: element.quantity,
						}
						Arrayproduct.push(obj)
					})
				}

				let ArrayBisnis = []
				if (this.getLoanBusinnessFX.list.length > 0) {
					this.getLoanBusinnessFX.list.forEach((element) => {
						let obj = {
							businessName: element.businessName,
							businessSize: element.businessSize,
							businessProduction: element.businessProduction,
							latitude: element.latitude,
							longitude: element.longitude,
						}
						ArrayBisnis.push(obj)
					})
				}
				const payload = {
					dataName: this.getLoanPetaniFX.Name,
					dataGenderId: this.getLoanPetaniFX.Gender,
					dataBirthday: this.getLoanPetaniFX.BirthDate,
					dataIdType: this.getLoanPetaniFX.IDType,
					dataObjectTypeId: 44, // default farmer dulu sementara
					dataEntityTypeId: 40, // person dulu sementara
					dataIdNumber: this.getLoanPetaniFX.IDNumber,
					familyWife: this.getLoanPetaniFX.WifeName,
					familyWifeIdType: this.getLoanPetaniFX.IDType, // ngikut ke ID Type personal
					familyWifeStatusId: "20", // default sementara
					familyWifeEntityTypeId: 40, // person dulu sementara
					familyWifeGender: "6", // female
					familyWifeIdNumber: this.getLoanPetaniFX.WifeIDNumber,
					certificationStatus: this.getLoanPetaniFX.StatusCert,
					certificationType: "",
					addressCountryId: this.getLoanPetaniFX.countryId,
					addressProvinceId: this.getLoanPetaniFX.Province,
					addressDistrictId: this.getLoanPetaniFX.District,
					addressSubDistrictId: this.getLoanPetaniFX.SubDistrict,
					addressVillageId: this.getLoanPetaniFX.Village,
					addressAddress: this.getLoanPetaniFX.Address,
					contactPhoneNumber: this.getLoanPetaniFX.PhoneNumber,
					contactEmail: this.getLoanPetaniFX.Email,
					accountBankId: this.getLoanPetaniFX.BankName !== undefined ? this.getLoanPetaniFX.BankName : "",
					accountHolder: this.getLoanPetaniFX.AccountHolderName !== undefined ? this.getLoanPetaniFX.AccountHolderName : "",
					accountNumber: this.getLoanPetaniFX.AccountNumber !== undefined ? this.getLoanPetaniFX.AccountNumber : "",
					deliveryType: this.getAmbilBarangFX,
					businessType: this.getLoanBusinnessFX.BusinessType,
					businessEst: this.getLoanBusinnessFX.BusinessEst,
					sellingTransaction: this.getLoanBusinnessFX.NumberOfTransaction,
					sellingTransactionSize: 0, // sementara di no kan saja
					sellingTransactionAmount: this.getLoanBusinnessFX.Amount,
					businessDetails: ArrayBisnis,
					documents: this.documentuploadList.length > 0 ? this.documentuploadList : [],
					entityFarmerUid: this.getLoanPetaniFX.entityUid === null ? "" : this.getLoanPetaniFX.entityUid, // kosongin dulu saja
					entityIdLoanInstitution: this.dataPackage[0].loanInstitutionId, // ini id package loanInstitutionId
					fieldAgent: this.loggedInUser.UserRealName, // nama user name login
					loanPackageId: this.dataPackage[0].loanPackageId, // loanPackageId
					numberOfInstallment: this.getLoanFXProduct.periode, // cicilan
					paymentOverdueDate: this.getLoanFXProduct.jatuhtempo, // tgl cicilan
					product: Arrayproduct,
					tenor: this.getLoanFXProduct.cicilan, // periode
					totalCashAmount: this.getLoanFXProduct.CashLoanRequest,
					totalLoanAmount: parseFloat(this.getLoanFXProduct.CashProductRequest) + parseFloat(this.getLoanFXProduct.CashLoanRequest), // ini kasus untuk case & product = totalCashAmount +  totalProductAmount
					totalProductAmount: this.getLoanFXProduct.CashProductRequest,
				}

				this.$swal({
					type: "warning",
					title: this.$t("Confirmation", "Confirmation"),
					text: this.$t("Are you sure you want to save the data?", "Are you sure you want to save the data?"),
					showConfirmButton: true,
					showCancelButton: true,
					confirmButtonText: this.$t("Submit", "Submit"),
					cancelButtonText: this.$t("Cancel", "Cancel"),
				}).then((isConfirm) => {
					if (isConfirm.value === true) {
						this.$swal.fire({
							title: `${this.$t("Loading", "Loading")}...`,
							html: this.$t("Data will be saved", "Data will be saved"),
							allowOutsideClick: false,
							onBeforeOpen: () => {
								this.$swal.showLoading()
							},
						})

						this.ActionAddNewSubmit(payload).then(() => {
							this.$router.push("/loan/application")
						})
					}
				})
			},
			uploadFile(event, entityListOfValueId) {
				const formData = new FormData()
				formData.append("file_data", event.target.files[0])
				formData.append("bucket_name", "koltiva-loan")
				formData.append("s3_dest_path", "loan/produk/")
				this.loadings = true
				this.ActionUploadS3(formData).then((response) => {
					const obj = {
						documentType: entityListOfValueId,
						loanDocumentObjectType: 132,
						documentUrl: response.s3_url,
					}
					this.documentuploadList.push(obj)
				})
			},
		},
	}
</script>

<style>
	.input {
		background: #fff;
	}
</style>

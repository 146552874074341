<template>
	<b-row>
		<b-col md="12">
			<ktv-card-with-header :header="$t('Collection of Goods', 'Collection of Goods')">
				<template #content>
					<b-form-radio-group v-model="form.pengambilan" :options="deliveryTypeOptions" class="mb-3" value-field="id" text-field="label" />
					<b-form-textarea
						id="textarea"
						v-model="form.alamatkirim"
						:placeholder="$t('Enter something', 'Enter something')"
						rows="3"
						max-rows="6"
						style="background: #fff; display: none"
					/>
				</template>
			</ktv-card-with-header>
			<b-row class="mt-3">
				<b-col align="left">
					<ktv-button :text="$t('Previous Page', 'Previous Page')" @click="$root.$refs.UtamaPage_component.previousPage()" />
				</b-col>
				<b-col align="right">
					<ktv-button :text="$t('Next Page', 'Next Page')" @click="onSubmit" />
				</b-col>
			</b-row>
		</b-col>
	</b-row>
</template>
<script>
	import { KtvCardWithHeader, KtvButton } from "@/components"
	import { mapState, mapActions, mapGetters } from "vuex"
	import { map } from "underscore"
	export default {
		name: "DeliveryPage",
		components: {
			KtvCardWithHeader,
			KtvButton,
		},
		props: {
			dataPackage: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				loading: true,
				form: {
					pengambilan: 170,
					alamatkirim: "",
				},
			}
		},
		computed: {
			...mapState("MASTER", ["optionDeliveryType"]),
			...mapGetters({
				getLoanPetaniFX: "LOANFX/getLoanPetaniFX",
			}),
			deliveryTypeOptions() {
				return map(this.optionDeliveryType, (element) => {
					return {
						id: element.entityListOfValueId,
						label: this.$t(this.capitalizeFirstLetter(element.value), this.capitalizeFirstLetter(element.value)),
					}
				})
			},
		},
		watch: {
			getLoanPetaniFX: {
				deep: true,
				handler() {
					this.getDeliveryData()
				},
				immediate: true,
			},
		},
		mounted() {
			this.actGetDeliveryType()
		},
		methods: {
			...mapActions({
				actGetDeliveryType: "MASTER/actGetDeliveryType",
			}),
			capitalizeFirstLetter(text) {
				return text.charAt(0).toUpperCase() + text.slice(1)
			},
			getDeliveryData() {
				let data = this.getLoanPetaniFX
				if (data.length > 0) {
					const Address = data.Address + " , Province " + data.Province.label + " , District " + data.SubDistrict.label
					this.form.alamatkirim = Address
				}
			},
			onSubmit() {
				this.$store.commit("LOANFX/setAmbilBarangFX", this.form.pengambilan)
				this.$root.$refs.UtamaPage_component.nextPage()
			},
		},
	}
</script>

<style>
	.input {
		background: #fff;
	}
</style>

<template>
	<b-row>
		<b-col md="12">
			<ktv-card-with-header :header="$t('Loan Information', 'Loan Information')">
				<template #content>
					<b-row>
						<b-form-group class="col-md-4 mb-2" :label="$t('Period', 'Period')">
							<b-form-select v-model="periode" :options="optionsperiode" />
						</b-form-group>
						<b-form-group class="col-md-4 mb-2" :label="$t('Due Date', 'Due Date')">
							<b-form-input v-model="jatuhtempo" min="1" max="31" type="number" class="input" @input="checkLimitTempo" />
						</b-form-group>
						<b-form-group class="col-md-4 mb-2" :label="$t('Number of Installment', 'Number of Installment')">
							<b-form-input v-model="cicilan" min="1" type="number" class="input" @input="checkLimitByPeriod" />
						</b-form-group>
					</b-row>
				</template>
			</ktv-card-with-header>

			<ktv-card-with-header class="mt-12" :header="$t('Select Loan Product', 'Select Loan Product')">
				<template #content>
					<b-row>
						<b-form-group class="col-md-2 mb-2" :label="$t('Currency', 'Currency')">
							<b-form-input v-model="Currency" type="text" readonly />
						</b-form-group>

						<b-form-group class="col-md-4 mb-2" :label="$t('Loan Package', 'Loan Package')">
							<b-form-input v-model="LoanPackage" type="text" readonly />
						</b-form-group>

						<!-- Cash only -->
						<b-form-group
							v-show="dataPackage[0].loanPackageType === 155 || dataPackage[0].loanPackageType === 157 ? true : false"
							class="col-md-3 mb-2"
							:label="$t('Maximum Cash Loan', 'Maximum Cash Loan')"
						>
							<b-form-input v-model="CashLoan" type="text" readonly />
						</b-form-group>
						<b-form-group
							v-show="dataPackage[0].loanPackageType === 155 || dataPackage[0].loanPackageType === 157 ? true : false"
							class="col-md-3 mb-2"
							:label="$t('Cash Loan Request', 'Cash Loan Request')"
						>
							<b-form-input
								v-model="CashLoanRequest"
								type="text"
								style="background: white"
								@keyup="onCashLoanRequestEnter($event)"
								@focus="onFocusCashLoanRequest"
								@blur="onBlurCashLoanRequest"
							/>
						</b-form-group>

						<!-- product only -->
						<b-form-group
							v-show="dataPackage[0].loanPackageType === 156 || dataPackage[0].loanPackageType === 157 ? true : false"
							class="col-md-6 mb-2"
							:label="$t('Product Maximum Loan', 'Product Maximum Loan')"
						>
							<b-form-input v-model="CashProduct" type="text" readonly />
						</b-form-group>
						<b-form-group
							v-show="dataPackage[0].loanPackageType === 156 || dataPackage[0].loanPackageType === 157 ? true : false"
							class="col-md-6 mb-2"
							:label="$t('Product Loan Request', 'Product Loan Request')"
						>
							<b-form-input v-model="CashProductRequest" type="text" readonly />
						</b-form-group>
						<!-- end product only -->
					</b-row>
					<b-card
						v-show="dataPackage[0].loanPackageType === 156 || dataPackage[0].loanPackageType === 157 ? true : false"
						border-variant="primary"
						:header="$t('Product Detail', 'Product Detail')"
						header-bg-variant="primary"
						header-text-variant="white"
						align="left"
						no-body
						class="mb-4"
					>
						<b-row class="mt-3">
							<b-col v-for="(row, index) in dataPackage[0].productDetail" :key="index" cols="6">
								<b-row>
									<b-col cols="3" class="m-2">
										<b-img center :src="imgsdata(row.image)" v-bind="propsImageProduct" />
									</b-col>
									<b-col cols="6">
										<b-form>
											<b-row>
												<b-form-group class="col-md-12" :label="$t('Agri Input Name', 'Agri Input Name')" label-for="input-1">
													<b-form-input :value="row.name" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-12" :label="$t('Price (/unit)', 'Price (/unit)')" label-for="input-1">
													<b-form-input :value="row.salePrice" type="text" readonly />
												</b-form-group>
												<b-form-group class="col-md-12" :label="$t('Quantity Request', 'Quantity Request')" label-for="input-1">
													<b-form-input
														:value="row.quantity"
														type="number"
														min="0"
														style="background: white"
														@keyup="onQtyApprovEnter(index, $event)"
													/>
												</b-form-group>
											</b-row>
										</b-form>
									</b-col>
								</b-row>
							</b-col>
						</b-row>
					</b-card>
				</template>
			</ktv-card-with-header>
			<b-row class="mt-3">
				<b-col align="left">
					<ktv-button :text="$t('Previous Page', 'Previous Page')" @click="$root.$refs.UtamaPage_component.previousPage()" />
				</b-col>
				<b-col align="right">
					<ktv-button :text="$t('Next Page', 'Next Page')" :disabled="isDisabledButtonNext" @click="onSubmit" />
				</b-col>
			</b-row>
		</b-col>
	</b-row>
</template>

<script>
	import { KtvCardWithHeader, KtvButton } from "@/components"
	import { mapGetters } from "vuex"
	import _ from "lodash"

	export default {
		name: "ProdukPage",
		components: {
			KtvCardWithHeader,
			KtvButton,
		},
		props: {
			dataPackage: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {
				optionsperiode: [1, 3, 6, 12],
				isDisabledButtonNext: false,
				propsImageProduct: { width: 100 },
				CashLoanRequest: "",
				CashProductRequest: "",
				periode: "",
				jatuhtempo: "",
				cicilan: "",
				collectPinjamanProduct: [],
			}
		},
		computed: {
			...mapGetters({
				getLoanFXProduct: "LOANFX/getLoanFXProduct",
			}),
			LoanPackage() {
				return this.dataPackage[0] !== undefined ? this.dataPackage[0].loanPackageName : ""
			},
			CashLoan() {
				return this.dataPackage[0] !== undefined ? this.formatPrice(this.dataPackage[0].totalCashAmount) : ""
			},
			CashProduct() {
				return this.dataPackage[0] !== undefined ? this.formatPrice(this.dataPackage[0].totalProductAmount) : ""
			},
			Currency() {
				return this.dataPackage[0] !== undefined ? this.dataPackage[0].currencyName : ""
			},
		},
		created() {
			// set componenent name
			this.$root.$refs.produkPage_component = this
		},
		methods: {
			imgsdata(image) {
				let img = ""
				if (image !== null) {
					const partsOfStr = image.split(",")
					img = "https://farmretail.s3-ap-southeast-1.amazonaws.com/produk/" + partsOfStr[0]
				}
				return img
			},
			formatPrice(value) {
				let val = (value / 1).toFixed().replace(".", ",")
				return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
			},
			onQtyApprovEnter: _.debounce(function (index, event) {
				let produkDetails = this.dataPackage[0].productDetail

				produkDetails[index] = {
					currency: produkDetails[index].currency,
					image: produkDetails[index].image,
					loanApplicationId: produkDetails[index].loanApplicationId,
					loanPackageCustomId: produkDetails[index].loanPackageCustomId,
					loanPackageId: produkDetails[index].loanPackageId,
					name: produkDetails[index].name,
					productId: produkDetails[index].productId,
					productUid: produkDetails[index].productUid,
					productUnitCode: produkDetails[index].productUnitCode,
					productUnitID: produkDetails[index].productUnitID,
					productUnitName: produkDetails[index].productUnitName,
					quantity: event.target.value,
					quantityApprove: 0,
					salePrice: produkDetails[index].salePrice,
					stock: produkDetails[index].stock,
				}

				let CollectProduct = []
				produkDetails.forEach((val) => {
					let productArray = {
						productId: val.productId,
						quantity: val.quantityApprove === 0 || val.quantityApprove === "0" ? val.quantity : val.quantityApprove,
						total_price: val.quantity * val.salePrice,
					}
					CollectProduct.push(productArray)
				})

				const totalProductLoanApprove = CollectProduct.reduce(function (acc, val) {
					return acc + parseFloat(val.total_price)
				}, 0)

				if (parseFloat(totalProductLoanApprove) > parseFloat(this.CashProduct.split(".").join(""))) {
					this.$swal(
						`${this.$t("Failed", "Failed")}!`,
						this.$t("Product loan request less than cash loan approved", "Product loan request less than cash loan approved"),
						"info",
					)
					this.isDisabledButtonNext = true
				} else {
					this.CashProductRequest = this.formatPrice(totalProductLoanApprove)
					if (this.dataPackage[0].loanPackageType === 157) {
						if (this.CashLoanRequest === "" || this.CashLoanRequest === 0 || this.CashProductRequest === "") {
							this.isDisabledButtonNext = true
						} else {
							this.isDisabledButtonNext = false
						}
					} else {
						this.isDisabledButtonNext = false
					}
				}

				this.collectPinjamanProduct = produkDetails
			}, 1000),
			onCashLoanRequestEnter: _.debounce(function (event) {
				const CashLoan = this.CashLoan.split(".").join("")
				const inputan = this.formatPrice(event.target.value)
				if (parseFloat(CashLoan) < parseFloat(inputan.split(".").join(""))) {
					this.$swal(
						`${this.$t("Failed", "Failed")}!`,
						this.$t("Cash loan request less than product maximum loan", "Cash loan request less than product maximum loan"),
						"info",
					)
					this.isDisabledButtonNext = true
				} else if (this.dataPackage[0].loanPackageType === 157) {
					if (inputan === "" || inputan === "0" || this.CashProductRequest === "") {
						this.isDisabledButtonNext = true
					} else {
						this.isDisabledButtonNext = false
					}
				} else if (this.dataPackage[0].loanPackageType === 155) {
					if (inputan === "" || inputan === "0") {
						this.isDisabledButtonNext = true
					} else {
						this.isDisabledButtonNext = false
					}
				} else if (parseFloat(inputan.split(".").join("")) === 0) {
					this.isDisabledButtonNext = false
					this.$swal(`${this.$t("Failed", "Failed")}!`, this.$t("Cash loan request not zero", "Cash loan request not zero"), "info")
				}
			}, 0),
			onFocusCashLoanRequest() {
				if (this.CashLoanRequest !== "" && this.CashLoanRequest !== 0) {
					this.CashLoanRequest = this.CashLoanRequest.split(".").join("")
				}
			},
			onBlurCashLoanRequest: _.debounce(function () {
				this.CashLoanRequest = this.formatPrice(this.CashLoanRequest.split(".").join(""))
			}, 500),
			onSubmit() {
				if (this.dataPackage[0].loanPackageType === 155 || this.dataPackage[0].loanPackageType === 157) {
					if (this.CashLoanRequest === "") {
						this.$swal(`${this.$t("Failed", "Failed")}!`, this.$t("Cash loan request not empty", "Cash loan request not empty"), "error")
						this.isDisabledButtonNext = true
					} else {
						// selanjutnya di klik, maka dapatlah produk yg di request
						// di simpan di state dan getters this.getLoanFXProduct
						this.collectPinjamanProduct.CashLoanRequest = this.CashLoanRequest === "" ? 0 : this.CashLoanRequest.replace(/\./g, "")
						this.collectPinjamanProduct.CashProductRequest = this.CashProductRequest === "" ? 0 : this.CashProductRequest.replace(/\./g, "")
						this.collectPinjamanProduct.periode = this.periode
						this.collectPinjamanProduct.jatuhtempo = this.jatuhtempo
						this.collectPinjamanProduct.cicilan = this.cicilan
						this.$store.commit("LOANFX/setLoanProductFX", this.collectPinjamanProduct)

						this.isDisabledButtonNext = false
						if (this.dataPackage[0].loanPackageType !== 157) this.$root.$refs.UtamaPage_component.nextPage()
					}
				}

				if (this.dataPackage[0].loanPackageType === 156 || this.dataPackage[0].loanPackageType === 157) {
					if (this.CashProductRequest === "") {
						this.$swal(
							`${this.$t("Failed", "Failed")}!`,
							this.$t("Cash product loan request not empty", "Cash product loan request not empty"),
							"error",
						)
						this.isDisabledButtonNext = true
					} else {
						// selanjutnya di klik, maka dapatlah produk yg di request
						// di simpan di state dan getters this.getLoanFXProduct
						this.collectPinjamanProduct.CashLoanRequest = this.CashLoanRequest === "" ? 0 : this.CashLoanRequest.replace(/\./g, "")
						this.collectPinjamanProduct.CashProductRequest = this.CashProductRequest === "" ? 0 : this.CashProductRequest.replace(/\./g, "")
						this.collectPinjamanProduct.periode = this.periode
						this.collectPinjamanProduct.jatuhtempo = this.jatuhtempo
						this.collectPinjamanProduct.cicilan = this.cicilan
						this.$store.commit("LOANFX/setLoanProductFX", this.collectPinjamanProduct)

						this.isDisabledButtonNext = false
						if (this.dataPackage[0].loanPackageType !== 157) this.$root.$refs.UtamaPage_component.nextPage()
					}
				}

				// ini isse 157 aja, next tabnya gak ke next 2 kali, jadi harus di pisah dengan yg diatas
				if (this.dataPackage[0].loanPackageType === 157) {
					if (this.CashLoanRequest !== "" && this.CashProductRequest !== "") this.$root.$refs.UtamaPage_component.nextPage()
				}
			},
			resetFormProduk() {
				this.CashLoanRequest = ""
				this.CashProductRequest = ""
				this.isDisabledButtonNext = true
			},
			checkLimitByPeriod() {
				const periode = parseInt(this.periode, 10)
				const cicilan = parseInt(this.cicilan, 10)
				if (periode < cicilan) {
					this.$swal(
						`${this.$t("Failed", "Failed")}!`,
						this.$t("The number of installment cannot be greater than the period", "The number of installment cannot be greater than the period"),
						"info",
					)
					this.cicilan = 1
				}
				if (cicilan < 0) {
					this.$swal(
						`${this.$t("Failed", "Failed")}!`,
						this.$t("The number of installment cannot be zero", "The number of installment cannot be zero"),
						"info",
					)
					this.cicilan = 1
				}
			},
			checkLimitTempo() {
				if (parseInt(this.jatuhtempo, 10) > 31) {
					this.jatuhtempo = 1
					this.$swal(`${this.$t("Failed", "Failed")}!`, this.$t("The maximum due date is 31", "The maximum due date is 31"), "info")
				}
			},
		},
	}
</script>

<style>
	.input {
		background: #fff;
	}
</style>

<template>
	<div>
		<!-- Loan Data -->
		<b-row>
			<b-form-group class="col-md-6 mb-2">
				<ktv-input v-model="form.WifeName" rules="required" :label="$t('Family Name', 'Family Name')" />
			</b-form-group>
			<b-form-group class="col-md-6 mb-2">
				<ktv-input v-model="form.WifeIDNumber" type="number" :label="$t('Family ID Number', 'Family ID Number')" />
			</b-form-group>
		</b-row>
	</div>
</template>
<script>
	import { mapGetters, mapActions } from "vuex"
	import { KtvInput } from "@/components"
	export default {
		components: {
			KtvInput,
		},
		data() {
			return {
				loading: true,
				form: {
					WifeName: "",
					WifeIDNumber: "",
				},
			}
		},
		computed: {
			...mapGetters({}),
		},
		methods: {
			...mapActions({}),
		},
	}
</script>
<style>
	.input {
		background: #fff;
	}
</style>

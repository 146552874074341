<template>
	<div>
		<!-- Loan Data -->
		<b-row>
			<b-form-group class="col-md-6 mb-2">
				<ktv-input v-model="form.PhoneNumber" type="number" :label="$t('Phone Number', 'Phone Number')" />
			</b-form-group>
			<b-form-group class="col-md-6 mb-2">
				<ktv-input v-model="form.Email" rules="email" type="text" :label="$t('Email', 'Email')" />
			</b-form-group>
		</b-row>
	</div>
</template>
<script>
	import { mapGetters, mapActions } from "vuex"
	import { KtvInput } from "@/components"

	export default {
		components: { KtvInput },
		props: {
			isfarmer: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				loading: true,
				form: {
					PhoneNumber: "",
					Email: "",
				},
			}
		},
		computed: {
			...mapGetters({
				getSearchResult: "LOANFX/getSearchResult",
			}),
		},
		watch: {
			dataFormSearch: {
				deep: true,
				handler() {
					this.getDatafromSearch()
				},
				immediate: true,
			},
		},
		methods: {
			...mapActions({}),
			getDatafromSearch() {
				if (this.getSearchResult.length > 0) {
					this.form.PhoneNumber = this.getSearchResult[0].contactPhoneNumber
					this.form.Email = this.getSearchResult[0].contactEmail
				}
			},
			getisfarmer() {
				if (this.isfarmer !== false) {
					this.form.PhoneNumber = ""
					this.form.Email = ""
				}
			},
		},
	}
</script>
<style>
	.input {
		background: #fff;
	}
</style>
